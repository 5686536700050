import {mapGetters, mapMutations, mapActions} from 'vuex';
import icons from "../../../../mixins/icons/index.js";

const _ = require('lodash');

export default {
    props: ['popupType', 'selectedItem', 'status'],
    mixins:[icons],
    data() {
        return {
            toggleTextarea:false,
            productCategoryList:[],
            form: {
                title: '',
                id:0,
                full_title: '',
                category_id: '',
                model_id: '',
                department_id: '',
                material: '',
                manufacturer_id: '',
                images: [{
                    image:'',
                }],
                sku: '',
                isbn: '',
                default_width:0,
                default_height:0,
                default_length:0,
                default_weight:0,
                description:'',
            },
            // hasImage: false,
            imageFile: null,
            imageSrc: null,
            imageUrl: '',

            fields: [
                {
                    key: 'department_id',
                    label: 'Сайт/Відділ',
                },
                {
                    key: 'price',
                    label: 'Ціна',
                },
                {
                    key: 'buy_min',
                    label: 'Мін. кількість'
                },
                {
                    key: 'buy_step',
                    label: 'Крок покупки'
                },
            ],
            items: [],
        }
    },
    computed: {
        ...mapGetters({
            product: 'catalog/product',
            productCategories: 'catalog/productCategories',
            manufacturers: 'catalog/manufacturers',
            departments: 'catalog/departments',
            baseUrl: 'config/baseUrl',
            models: 'catalog/models',
        }),
    },
    watch: {
        product(e) {
            if (e) {
                this.imageSrc = e.images[0].image;

                this.form = {
                    title: e.title ? e.title : '',
                    id: e.id ? e.id : 0,
                    full_title: e.full_title ? e.full_title : '',
                    category_id: e.category_id ? e.category_id : null,
                    model_id: e.model_id ? e.model_id : null,
                    department_id: e.department_id ? e.department_id : null,
                    manufacturer_id: e.manufacturer_id ? e.manufacturer_id : null,
                    description: e.description ? e.description : null,
                    sku: e.sku ? e.sku : '',
                    isbn: e.isbn ? e.isbn : '',
                    images: e.images ? e.images : '',
                    upsell_manager_motivation: e.discount_price ? Number(e.upsell_manager_motivation).toFixed(0) : 0,
                    minimum: e.minimum ? e.minimum : 0,
                }
                this.items = []
                if(e.prices){
                    e.prices.map(itemPrice =>{
                        this.items.push({
                            id: itemPrice.id,
                            department_id: itemPrice.department_id,
                            price: Number(itemPrice.price).toFixed(2),
                            buy_min: itemPrice.buy_min,
                            buy_step: itemPrice.buy_step,
                        })
                    })
                }
            }
        },
        productCategories(e){
            this.productCategoryList = _.clone(e.data);
            this.productCategoryList.map(item=>{
                if(item.children.length){
                    item.children.map(childMap=>{
                        this.productCategoryList.push(childMap);
                    })
                }
            })
        },
    },
    created() {
        this.form.isbn = this.$moment().format("DD MM YYYY HH mm ss").split(' ').join('').slice(1, 14);
    },
    methods: {
        getPriceDepartmentName(id){
            const department = this.departments.data.filter(departmentItem => departmentItem.id === id)[0]
            return department.title
        },
        getProductCategory(id){
            const result =   this.productCategoryList.filter(item => item.id === id)[0]
            return result ? result.title : 'Категорія не вибрана';
        },
        getProductModel(id){
            const result =  id ? this.models.data.filter(item => item.id === id)[0] : null
            return result ? result.title : 'Модель не вибрана';
        },
        getProductManufactures(id){
            const result = id ? this.manufacturers.data.filter(item => item.id === id)[0] : null
            return result ? result.title : 'Виробник не вибраний';
        },
        getProductDepartment(id){
            const result = id ? this.departments.data.filter(item => item.id === id)[0] : null
            return result ? result.title : 'Відділ/Сайт не вибраний';
        },
        changeFile(e) {
            if(!e.target.files[0]) {
                return
            }
            new Promise((resolve, reject) => {
                const reader = new FileReader()
                if(!e.target.files[0]) {
                    console.log('Select file please!')
                }
                reader.readAsDataURL(e.target.files[0])
                reader.onload = () => resolve(this.imageSrc = reader.result.toString())
                reader.onerror = error => reject(error)
            })
        },
        removeImage() {
            this.imageSrc = null
            this.form.images = null
            this.imageFile = null
        },
        closePopup() {
            this.$emit('closePopup');
            this.changeShowProductPopup(false)
        },
        sendModel() {
            this.form.current_buy_price = Number(this.form.current_buy_price);
            if(this.imageFile){
                this.storeProductImage(this.imageFile).then( res => {
                    this.form.images[0].image = res.image;
                    if (this.product) {
                        const payload = {
                            id: this.product.id,
                            data: this.form
                        }
                        this.$emit('changeProduct', payload)
                    } else {
                        this.$emit('sendModel', this.form)
                    }
                })

            }else{
                if (this.product) {
                    const payload = {
                        id: this.product.id,
                        data: this.form
                    }
                    this.$emit('changeProduct', payload)
                } else {
                    this.$emit('sendModel', this.form)
                }
            }
        },
        ...mapActions({
            storeProductImage: 'catalog/storeProductImage',
        }),
        ...mapMutations({
            changeProduct: 'catalog/changeProduct',
            changeShowProductPopup: 'catalog/changeShowProductPopup',
        }),
    },    beforeDestroy() {

        this.changeProduct(null);

        this.toggleTextarea = false;
        this.productCategoryList = [];
        this.form = {
            title: '',
            id:0,
            full_title: '',
            category_id: '',
            model_id: '',
            department_id: '',
            material: '',
            manufacturer_id: '',
            images: [{
                image:'',
            }],
            sku: '',
            isbn: '',
            default_width:0,
            default_height:0,
            default_length:0,
            default_weight:0,
        };
        // hasImage: false,
        this.imageFile = null;
        this.imageSrc = null;
        this.imageUrl = '';
    },
}
